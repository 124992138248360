import React, { useState, useEffect } from 'react';

import Trusts from '../../components/trusts/Trusts'
import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <Trusts />
        </Layout>
    )
}

export default Index
